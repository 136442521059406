<template>
  <v-container class="white py-0 px-0" fluid>
    <v-card flat>
      <v-toolbar flat dense class="grey lighten-5">
        <v-toolbar-title class="py-3 px-0">{{
          $t("route.dashboards")
        }}</v-toolbar-title>
        <v-spacer />
        <v-divider vertical inset class="d-none d-md-flex mx-2" />
        <v-text-field
          class="d-none d-md-flex"
          v-model="search"
          append-icon="mdi-magnify"
          :label="$t('general.search')"
          single-line
          hide-details
          dense
          style="width: 0"
        ></v-text-field>
      </v-toolbar>

      <v-card-text class="overflow-y-auto" :style="viewHeight(50)">
        <v-row>
          <v-col
            v-for="(item, i) in getDashboards"
            :key="i"
            v-show="item.enabled"
            cols="12"
            md="4"
            sm="6"
          >
            <v-card min-height="230" :disabled="!item.enabled">
              <v-img
                class="white--text align-end"
                height="100px"
                :src="item.image"
              >
                <v-card-title>{{ item.name }}</v-card-title>
              </v-img>

              <v-card-text class="text--primary">
                <div>{{ item.description }}</div>
              </v-card-text>

              <v-divider></v-divider>
              <v-card-actions>
                <v-btn
                  color="primary"
                  link
                  tile
                  text
                  @click="openDashboard(item)"
                >
                  <v-icon>mdi-file-chart</v-icon>
                  Open
                </v-btn>
                <v-spacer />
                <v-progress-circular
                  v-if="loading.srs"
                  :indeterminate="loading.srs"
                  color="primary"
                ></v-progress-circular>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>

        <!-- <v-card class="d-flex flex-wrap justify-start" flat tile>
          <v-card
            class="ma-3"
            max-width="300"
            v-for="(item, i) in dashboards"
            :key="i"
            v-show="item.enabled"
            :disabled="!item.enabled"
          >
            <v-img
              class="white--text align-end"
              height="100px"
              :src="item.image"
            >
              <v-card-title>{{ $t(item.name) }}</v-card-title>
            </v-img>

            <v-card-text class="text--primary">
              <div>{{ $t(item.description) }}</div>
            </v-card-text>

            <v-divider></v-divider>
            <v-card-actions>
              <v-btn
                color="orange"
                text
                link
                @click="() => $router.push({ name: item.link })"
              >
                Open
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-card> -->

        <v-card flat v-if="dashboards.filter((f) => f.enabled).length === 0" class="my-3">
          No dashboards available yet.
        </v-card>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters, mapActions, mapState } from "vuex";

export default {
  name: "Dashboards",
  data() {
    return {
      loading: { srs: false },
      search: "",
      dashboards: [
        {
          id: "srs",
          name: this.$t("dashboard.srsDashboard"),
          description: this.$t("dashboard.rnaDashboardDesc"),
          link: "srs-dashboard",
          enabled: this.$allowed("srs:incidents"),
          image: "/images/dashboard-srs.jpg",
        },
      ],
    };
  },
  computed: {
    ...mapGetters("organizations", ["getOrganizations"]),
    ...mapState({
      getDashboards() {
        if (!this.search) {
          return this.dashboards;
        } else {
          const s = this.search.toLowerCase();
          return this.dashboards.filter(
            (f) =>
              f.name.toLowerCase().indexOf(s) !== -1 ||
              f.description.toLowerCase().indexOf(s) !== -1
          );
        }
      },
    }),
  },
  mounted() {
    this.loadOrganizations();
  },
  methods: {
    ...mapActions("organizations", ["loadOrganizations"]),
    ...mapActions("ui", ["showDialog", "closeDialog"]),

    openDashboard(item) {
      this.loading.srs = true;
      this.$router.push({ name: item.link });
    },
  },
};
</script>

<style scoped>
.v-card__title {
  word-break: break-word;
}
</style>
